.container-ratio {
  padding-right: 24.5px;
  padding-left: calc(100% - 1324.5px);
  width: 100%;
}
.container-margin-bottom {
  margin-bottom: 75px;
}
.section-heading {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}
img,
video {
  max-width: 100%;
  height: auto;
}
h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child {
  margin-bottom: 0;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
  margin-top: 0;
}
h3 {
  font-size: 24px;
  line-height: 1.8;
  font-weight: 700;
}
.section-heading strong.section-heading-margin-right {
  margin-right: 15px;
}
small {
  font-size: 15px;
  line-height: inherit;
  word-break: inherit;
}
/*=============== Journal ===============*/
.journal__card {
  position: relative;
  width: 200px !important;
  /* overflow: var(--img-hidden); */
  margin-left: 20px;
}

.journal__data {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
}

.journal__title, 
.journal__description {
  color: #ffffff;
}

.journal__title {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  line-height: 1.2;
}

.journal__description {
  display: block;
  font-size: 0.75rem;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: none;
}
.mySwiper{
  width: 95%;
}
@media screen and (max-width: 1800px){
  .container-ratio {
      padding-left: calc(100% - 1425px);
      padding-right: calc(100% - 1425px);
  }
}
@media screen and (max-width: 1440px){
  .container-ratio {
      padding-left: calc(100% - 1200px);
      padding-right: calc(100% - 1200px);
  }
}

@media screen and (max-width: 1100px){
  .container-ratio {
      padding-left: 25px;
      padding-right: 25px;
  }
}

@media screen and (max-width: 901px){
  .container-ratio {
      padding-left: 22px;
      padding-right: 22px;
  }
}

@media screen and (min-width: 768px) {
  .journal__container {
    width: 610px;
    margin-left: auto;
    margin-right: auto;
  }
  .journal__container,
  .place__container {
    padding-top: 2rem;
    margin-top: 5rem;
  }
  .journal__title {
    font-size: 1.05rem;
    
  }
}

@media screen and (max-width: 601px){
  .container-margin-bottom {
      margin-bottom: 60px;
  }
  .container-ratio {
    padding: 0 !important;
  }
  .section-heading {
    padding: 0 22px;
  }
  small {
    font-size: 14px;
    display: block;
    margin-bottom: 30px;
  }
}

/* For large devices */
@media screen and (min-width: 1024px) {
  .journal__card {
    width: 237px;
  }
  .journal__container {
    width: 700px;
  }
  .journal__data {
    left: 1.5rem;
    bottom: 2rem;
  }
  .journal__title {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
  }
  .journal__description {
    font-size: 0.813rem;
  }
}


