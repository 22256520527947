.policy_wrap .policy_logo{
  position:absolute;
  top: 20px;
  left: 20px;
  font-weight: bold;
}
.policy_wrap[_ngcontent-yix-c105] {
  background-color: #fff;
}

.policy_wrap[_ngcontent-yix-c105] article[_ngcontent-yix-c105] {
    padding: 10px;
    font-size: 14px;
    line-height: 24px;
}

.policy_wrap[_ngcontent-yix-c105] .policy_title[_ngcontent-yix-c105] {
  font-size: 27px;
}

.policy_wrap[_ngcontent-yix-c105] article[_ngcontent-yix-c105] {
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
}

.policy_wrap[_ngcontent-yix-c105] article[_ngcontent-yix-c105] h1[_ngcontent-yix-c105] {
  padding: 20px 0 5px;
  font-size: 20px;
  font-weight: 600;
}

.policy_wrap[_ngcontent-yix-c105] article[_ngcontent-yix-c105] {
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
}

.policy_wrap[_ngcontent-yix-c105] article[_ngcontent-yix-c105] h2[_ngcontent-yix-c105] {
  padding: 15px 0 5px;
  font-weight: 500;
  font-size: 16px;
}

.policy_wrap[_ngcontent-yix-c105] p[_ngcontent-yix-c105] {
  padding-bottom: 7px;
}

.policy_wrap[_ngcontent-yix-c105] article[_ngcontent-yix-c105] {
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
}

.policy_wrap[_ngcontent-yix-c105] ol[_ngcontent-yix-c105] li[_ngcontent-yix-c105] {
  margin-left: 20px;
  list-style-type: decimal;
}

.policy_wrap[_ngcontent-yix-c105] ul[_ngcontent-yix-c105] li[_ngcontent-yix-c105] {
  margin-left: 20px;
  list-style-type: square;
}

a:visited {
  color: #fff;
}
::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size:14px;
  line-height: 24px;
}

@media screen and (max-width: 540px){
  .has_banner .policy_wrap {
      padding-top: 180px;
  }
  .policy_wrap[_ngcontent-yix-c105] {
    padding: 130px 20px 118px;
  }
}

@media screen and (min-width: 541px){
  .has_banner .policy_wrap {
      padding-top: 336px;
  }
  .policy_wrap[_ngcontent-yix-c105] {
    min-width: 900px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 130px 50px 200px;
  }
}
