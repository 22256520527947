.journal-image-box {
  width:100%;
  height:400vh;
  overflow:hidden;
  margin:0 auto;
  margin-top: 66px;
  text-align: center;
}

.journal-image {
  width:750px;
  height:100%;
  object-fit:cover;
  object-position: 0 0;
}

.fader {
  margin-top: -500px;
  height: 500px;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, var(--primary-bg) 60%);
  z-index: 200;
}

.join.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #282828;
  background-color: var(--primary-bg) !important;
  z-index: 200 !important;
  position: relative;
  border-bottom: 1px solid black;
}

.join.banner .content {
  padding: 30px 0 120px 0;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  color: #ffffff;
}

.banner .content {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.join.banner > .dividing.header {
  width: 100%;
  border-bottom: 0;
  line-height: 1.7 !important;
  text-align: center;
  font-size: 26px;
  margin-top: 70px;
  margin-bottom: 0;
}

p:first-child {
  margin-top: 0;
}

.join-banner-text {
  margin-bottom: 60px;
  line-height: 1.8;
}

.ui.header {
  border: none;
  margin: calc(2rem - 0.1428571428571429em) 0 1rem;
  padding: 0 0;
  /* font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; */
  font-weight: 700;
  line-height: 1.28571429em;
  text-transform: none;
  color: #ffffff;
}

.appBtnDiv {
  text-align: center;
  width: 98%;
}

.appBtn {
  background: transparent;
  border: none;
  color: white;
  font-size: clamp(1.3rem, 5vw, 1.7rem);
  letter-spacing: 0.1rem;
  transition: background 0.3s ease;
  margin: 3rem;
  display: block;
}

@media screen and (max-width: 480px){
  .join.banner > .dividing.header {
      font-size: 24px;
  }
  .banner .content > p:not(.header) {
    font-size: 18px;
  }
  .journal-image-box {
    width:100%;
    height:240vh;
  }
  
  .journal-image {
    width:100%;
    height:100%;
  }
}