#main-content {
  width: 100%;
  height: auto;
}

#main-today-note.active, #main-commercial-note.active {
  margin-top: 98px;
  display: block;
}

.container-ratio {
  padding-right: 24.5px;
  padding-left: calc(100% - 1324.5px);
  width: 100%;
}

.container-margin-bottom {
  margin-bottom: 75px;
}

#main-today-note.active .today-note-link, #main-commercial-note.active .commerce-note-link {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  height: 630px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: rgb(40, 40, 40);
  color: white;
}

#today-note-img, #commerce-note-img {
  position: relative;
  width: 60%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ratio-4-3 {
  width: 100%;
  position: relative;
}
.ratio-4-3:after {
  padding-top: calc(100% * 3 / 4);
  display: block;
  content: '';
}

.bg-img {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  background-image: url('../../assets/images/mainbanner-02.png');
}

a {
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #4183c4;
  text-decoration: none;
}

a {
  background-color: transparent;
}

#today-note, #commerce-note {
  width: 40%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#today-note-tag-only {
  position: absolute;
  left: 0;
  background-color: #282828;
  /* color: rgb(255, 81, 38); */
  color: #f9b441;
  padding: 12px 35px;
}

h3 {
  font-size: 24px;
  line-height: 1.8;
  font-weight: 700;
}

h3 strong {
  font-weight: 700;
}

.today-note-item-wrapper {
  padding: 40px 35px 50px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.8;
  font-weight: 700;
}

p:last-child {
  margin-bottom: 0;
}

.today-note-desc {
  padding-right: 35px;
  line-height: 1.7;
  font-size: 17px;
  color: rgb(220, 220, 220);
}

.today-note-item {
  width: 100%;
  height: auto;
  color: white;
}

/* #timer-container {
  padding-bottom: 13px;
  margin-bottom: 50px;
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
} */

#timer-container {
  padding-bottom: 13px;
  margin-bottom: 50px;
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid white;
}

/* #timer-sub {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
} */
#timer-sub {
  width: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

#clock-icon {
  position: relative;
  top: 4px;
  width: 40px;
  height: 40px;
}

img {
  border-style: none;
}

/* #clock-icon-sub {
  position: relative;
  top: 4px;
  padding: 0 10px;
  font-size: 15px;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1.45;
} */

#clock-icon-sub {
  /* color: rgb(255, 81, 38); */
  color: #f9b441;
  font-size: 24px;
  line-height: 1.8;
  font-weight: 700;
}

p:last-child {
  margin-bottom: 0;
}

p {
  font-size: 19px;
  line-height: 1.8;
  font-weight: 400;
}

#clock-icon-sub small {
  line-height: inherit;
  margin-bottom: 0px;
}

small {
  font-size: 15px;
  line-height: inherit;
  word-break: inherit;
}

#timer {
  width: 250px;
  margin-left: -8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.timer-item {
  position: relative;
  top: -4px;
  display: inline-block;
  width: calc((100% - 30px) / 3);
  font-size: 45px;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  text-align: center;
}

.timer-item-devide {
  position: relative;
  top: -8px;
  display: inline-block;
  width: 15px;
  font-size: 50px;
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  text-align: center;
}

.today-note-item {
  width: 100%;
  height: auto;
  color: white;
}

p:first-child {
  margin-top: 0;
}

.today-note-heading {
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 1;
  /* color: rgb(255, 81, 38); */
  color: #f9b441;
}

.today-note-title {
  letter-spacing: -1px;
  margin-bottom: 25px;
  padding-right: 35px;
  line-height: 1.45;
}

.today-note-title-sub {
  letter-spacing: -1px;
  margin-bottom: 35px;
  padding-right: 35px;
  line-height: 1.45;
}

.circle-punctuation {
  margin-left: 8px;
  margin-bottom: 0px;
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 100%;
  /* background-color: rgb(255, 81, 38); */
  background-color: #f9b441;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
  margin-top: 0;
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1800px){
  .container-ratio {
      padding-left: calc(100% - 1225px);
  }
  #today-note-img, #commerce-note-img {
    width: 57%;
  }
  #today-note, #commerce-note {
    width: 43%;
  }
}

@media screen and (max-width: 1440px){
  .container-ratio {
      padding-left: calc(100% - 1000px);
  }
  #today-note-img, #commerce-note-img {
    width: 50%;
  }
  #today-note, #commerce-note {
    width: 50%;
  }
  .today-note-item {
    width: 100%;
    height: auto;
  } 
  .today-note-item {
    width: 100%;
    height: auto;
  }
  
}

@media screen and (max-width: 1100px){
  .container-ratio {
      padding-left: 50px;
  }
  #main-today-note.active .today-note-link, #main-commercial-note.active .commerce-note-link {
    height: 630px;
  }
  #timer-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
  }
  #timer-sub {
    width: 100%;
  } 
  #clock-icon {
    position: relative;
    top: 4px;
    width: 24px;
    height: 24px;
  }
  #clock-icon-sub {
    padding: 0 10px;
  }
}

@media screen and (max-width: 901px){
  .container-ratio {
      padding-left: 44px;
      padding-right: 22px;
  }
  #main-today-note.active .today-note-link, #main-commercial-note.active .commerce-note-link {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: white;
    height: auto !important;
  }
  #today-note-img, #commerce-note-img {
    width: 100%;
  }
  #today-note, #commerce-note {
    width: 100%;
    height: auto;
  }
  #today-note-tag-only {
    margin-top: -52px;
    padding: 8px 35px;
  }
  #today-note-tag-only > h3 {
    font-size: 22px;
  }
  .today-note-item:nth-child(1) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  #timer-container {
    width: 100%;
  }
  .today-note-title {
    padding-right: calc(100% / 3);
  }
  .today-note-title-sub {
    padding-right: calc(100% / 3);
  }
  .today-note-desc {
    padding-right: calc(100% / 3);
  }
}

@media screen and (max-width: 768px){
  .today-note-title {
      padding-right: calc(100% / 5);
  }
  .today-note-title-sub {
    padding-right: calc(100% / 5);
}
  .today-note-desc {
    padding-right: calc(100% / 5);
  }
}
@media screen and (max-width: 601px){
  .container-margin-bottom {
      margin-bottom: 60px;
  }
  .container-ratio {
    padding: 0 !important;
  }
  .today-note-item-wrapper {
    padding: 25px 22px 50px 22px;
  }
  #timer-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  #timer-sub {
    width: 100%;
    margin-bottom: 15px;
  } 
  #clock-icon-sub {
    padding: 0 12px;
  }
  .small {
    font-size: 14px;
    margin-bottom:0;
  }
  #timer {
    position: relative;
    margin-left: -2px;
  }
  .today-note-title {
    padding-right: 35px;
  }
  .today-note-title-sub {
    padding-right: 35px;
  }
  .today-note-desc {
    word-break: break-all;
  }
  .today-note-desc {
    padding-right: 35px;
  }
  #main-today-note.active, #main-commercial-note.active {
    margin-top: 70px;
  }
}