.navbar {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  padding: 1rem 0;
}

.navbar .link {
  color: rgb(71, 71, 71);
  margin: 0 1rem;
  font-size: 1.5rem;
  text-decoration: none;
}

#responsive-navbar-nav .link:hover {
  color: rgb(0, 0, 0);
}

@media (max-width: 768px) {
  #responsive-navbar-nav .link {
    padding-top: 1rem;
  }
}
