
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:'Pretendard', 'Noto Sans CJK KR', sans-serif;
  -webkit-font-smoothing: antialiased;
}

:root {
  --primary-bg: #9b8a79;
  --primary-text: #707070;
  --secondary-text: #ffffff;
}

@font-face {
  font-family: "PretendardBlack";
  src: local("PretendardBlack"),
   url("./assets/fonts/PRETENDARD-BLACK.TTF") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "PretendardMedium";
  src: local("PretendardMedium"),
   url("./assets/fonts/PRETENDARD-MEDIUM.TTF") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "PretendardRegular";
  src: local("PretendardRegular"),
   url("./assets/fonts/PRETENDARD-REGULAR.TTF") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "PretendardLight";
  src: local("PretendardLight"),
   url("./assets/fonts/PRETENDARD-LIGHT.TTF") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "VolanteBold";
  src: local("VolanteBold"),
   url("./assets/fonts/volante-bold.otf") format("opentype");
  font-weight: bold;
 }

 @font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/NotoSansKR-Light.woff2") format('woff2'),
  url("./assets/fonts/NotoSansKR-Light.woff") format('woff'),
  url("./assets/fonts/NotoSansKR-Light.otf") format('truetype')
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/NotoSansKR-Regular.woff2") format('woff2'),
  url("./assets/fonts/NotoSansKR-Regular.woff") format('woff'),
  url("./assets/fonts/NotoSansKR-Regular.otf") format('truetype')
}


@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/NotoSansKR-Medium.woff2") format('woff2'),
  url("./assets/fonts/NotoSansKR-Medium.woff") format('woff'),
  url("./assets/fonts/NotoSansKR-Medium.otf") format('truetype')
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/NotoSansKR-Bold.woff2") format('woff2'),
  url("./assets/fonts/NotoSansKR-Bold.woff") format('woff'),
  url("./assets/fonts/NotoSansKR-Bold.otf") format('truetype')
}


