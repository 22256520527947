
html {
    font-family: 'Noto Sans KR', sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

main {
    display: block;
}

a,
a:hover,
a:active,
a:visited {
    color: #fff;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

.privacy_policy_terms {
    max-width: 100%;
    margin: 0 auto;
    padding: 60px 16px;
    color: #000;
    line-height: 1.5;
    background-color: #fff;
}

.privacy_policy_terms h1 {
    text-align: center;
    margin: 0 0 1.5em 0;
}

.privacy_policy_terms h2 {
    margin: 1.5em 0 0 0;
}

.privacy_policy_terms table {
    margin: 10px 0 30px 0;
    width: 100%;
}

.privacy_policy_terms table,
.privacy_policy_terms td,
.privacy_policy_terms th {
    border: 1px solid #433a40;
    border-collapse: collapse;
}

.privacy_policy_terms td,
.privacy_policy_terms th {
    font-weight: normal;
    padding: 5px;
}

.privacy_policy_terms th {
    background-color: #ccc;
}

.privacy_policy_terms td {
    text-align: center;
}

.privacy_policy_terms td.t_bg {
    background-color: #ccc;
}

.privacy_policy_terms ol {
    padding-inline-start: 20px;
}

.privacy_policy_terms ul {
    padding-inline-start: 20px;
}

.privacy_policy_terms ol ul {
    padding-inline-start: 8px;
}

.box_line {
    border: 1px solid #433a40;
    padding: 1em;
}

.box_line P {
    margin-block-start: 0em;
    margin-block-end: 0.5em;
}

.box_line li {
    list-style-type: none;
}

.box_line li p {
    text-indent: 5px;
}

.privacy_policy_terms ol.index_num {
    padding-inline-start: 24px;
}

@media (max-width:320px) {
    .privacy_policy_terms h1 {
        font-size: 1.7em;
    }
}

@media (min-width:768px) {}

@media (min-width:1280px) {
    .privacy_policy_terms {
        max-width: 950px;
    }
}

.resize-observer[data-v-8859cc6c]{position:absolute;top:0;left:0;z-index:-1;width:100%;height:100%;border:none;background-color:transparent;pointer-events:none;display:block;overflow:hidden;opacity:0}.resize-observer[data-v-8859cc6c] object{display:block;position:absolute;top:0;left:0;height:100%;width:100%;overflow:hidden;pointer-events:none;z-index:-1}