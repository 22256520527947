#applink {
  background: linear-gradient(rgba(0, 0, 0, 0.582), rgba(0, 0, 0, 0.582)),
    url("../../assets/images/applinkImg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 80vh;
  color: white;
  position: relative;
}

#applink .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#applink .applinkTitle {
  /* font-size: clamp(4.7rem, 8vw, 9.5rem); */
  font-size: 3rem;
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
  padding: 3rem 0;
}

#applink .appBtnDiv {
  text-align: center;
  width: 98%;
}

#applink .appBtn {
  background: transparent;
  border: none;
  color: white;
  font-size: clamp(1.3rem, 5vw, 1.7rem);
  letter-spacing: 0.1rem;
  transition: background 0.3s ease;
  margin: 3rem;
  display: block;
}

@media only screen and (max-width: 768px) {
  #applink {
    height: 90vh;
  }
}